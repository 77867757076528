import React, { useEffect, useState } from 'react';
import { isLoading, updateNetwork, updateNetworkRule } from 'redux/slicers/accounts';
import Progress from '../../widgets/progress';
import Switch from '../../widgets/switch';
import FrequencyCapping from './frequency-capping/FrequencyCapping';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../widgets/Input';
import { selectPermissions } from 'redux/slicers/permissions';
import { SPLIT_CONSTANTS } from '../../../services/split.io';
import { useSplit } from '../../../hooks';
import { MarketoService } from '../../../services/api/marketo.service';
import { HubspotService } from '../../../services/api/hubspot.service';
import { getNetworkSettings, updateIsSsoEnabled } from './api/ssoEnabled';

const SettingsTab = ({ accountId, networks, network, setDialogControlGroupIsOpen, setDialogResetPasswordIsOpen }) => {
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);
    const license = useSelector((state) => state.accounts.license[accountId]);
    const account = useSelector((state) => state.accounts.accounts.find((a) => a.id === accountId));
    const { isOn: isPixelGuardV2Enabled } = useSplit(SPLIT_CONSTANTS.SPLIT_PIXEL_GUARD_V2);
    const userCanViewLoginInfo = permissions.internal_brand_admin && permissions.internal_brand_admin?.write && account && account.internalAdmin && account.internalAdmin?.active;
    const enableFrequencyCapping = networks && !!networks[0]?.isFrequencyCapping;
    const enableAffiliateFraudProtection = networks && !!networks[0]?.isAffiliateFraudEnabled;
    const enableGAConnector = network?.isGAConnectorEnabled;
    const pxgVersion = network?.pxgVersion;
    const [isMarketoMonitoring, setIsMarketoMonitoring] = useState(false);
    const [isHubspotMonitoring, setIsHubspotMonitoring] = useState(false);
    const [marloading, setMarLoading] = useState(true);
    const [hubloading, setHubLoading] = useState(true);
    const [newNetworkSettings, setNewNetworkSettings] = React.useState({});
    const [isEnabledSSOLoading, setIsEnabledSSOLoading] = React.useState(false);

    React.useEffect(() => {
        setIsEnabledSSOLoading(true)
        getNetworkSettings(network.id).then((res) => {
            setNewNetworkSettings(res)
            setIsEnabledSSOLoading(false)
        })
    }, [network]);

    useEffect(() => {
        try{
            MarketoService.getMonitoringMode(network.id).then(({is_monitoring: isMonitoringMode}) => {
                setIsMarketoMonitoring(isMonitoringMode);
                setMarLoading(false)
            });
            HubspotService.getMonitoringMode(network.id).then(({is_monitoring: isMonitoringMode}) => {
                setIsHubspotMonitoring(isMonitoringMode);
                setHubLoading(false)
            });
        }
        catch(e) {
            console.error('Error during getting monitoring mode from marketo / hubspot:', e);
            setMarLoading(false)
            setHubLoading(false)
        }
    }, []);

    React.useEffect(() => {
        setIsEnabledSSOLoading(true)
        getNetworkSettings(network.id).then((res) => {
            setNewNetworkSettings(res)
            setIsEnabledSSOLoading(false)
        })
    }, [network]);

    const isPaidMarketingLicenseActivate = () => {
        const paidMarketingLicense = license.products.find((element) => element.id === 1);
        return !!paidMarketingLicense;
    };

    const isDataAndAnalyticsLicenseActivate = () => {
        const dataAndAnalyticsLicense = license.products.find((element) => element.id === 3);
        return !!dataAndAnalyticsLicense;
    };

    const onChangeAffiliateFraud = () => {
        const network = networks[0];
        if (!network) return;
        dispatch(
            updateNetwork({
                id: network.id,
                isAffiliateFraudEnabled: !network?.isAffiliateFraudEnabled,
                accountId: account?.id,
            })
        );
    };

    const onChangeGAConnector = () => {
        if (!network) return;
        dispatch(
            updateNetwork({
                id: network.id,
                isGAConnectorEnabled: !network?.isGAConnectorEnabled,
                accountId: account?.id,
            })
        );
    };

    const onChangePxgVersion = () => {
        if (!network) return;
        dispatch(
            updateNetwork({
                id: network.id,
                pxgVersion: !network?.pxgVersion,
                accountId: account?.id,
            })
        );
    };

    const onChangeMarketoToggle = async () => {
        try {
            await MarketoService.setMonitoringMode(!isMarketoMonitoring, network.id);
            setIsMarketoMonitoring(!isMarketoMonitoring);
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeHubspotToggle = async () => {
        try {
            await HubspotService.setMonitoringMode(!isHubspotMonitoring, network.id);
            setIsHubspotMonitoring(!isHubspotMonitoring);
        } catch (error) {
            console.error(error);
        }
    };

    const onSSOConfiguration = (value) => {
        updateIsSsoEnabled(network.id,value).then((res) => {
            setNewNetworkSettings((prevState) => ({
                ...prevState,
                isSSOLoginEnabled: value
            }));
        });
    };

    const resetPasswordSection = () => {
        return userCanViewLoginInfo ? (
            <div>
                <p className="cq-heading">Login Info</p>
                <p className="field-key">Editing the login credentials will override the internal admin's existing settings</p>
                <div className="field-group">
                    <Input
                        style={{
                            backgroundColor: 'lightgrey',
                            opacity: '0.8',
                            width: '200px',
                            height: '26px',
                        }}
                        type={'text'}
                        disabled={true}
                        readOnly={true}
                        value={account?.internalAdmin.username}
                    />
                    <div style={{ marginTop: '5px' }}>
                        <span
                            className="reset-password"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                setDialogResetPasswordIsOpen(true);
                            }}
                        >
                            RESET PASSWORD
                        </span>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    };
    return (
        <div className="account-settings-container">
            {isPaidMarketingLicenseActivate() && (
                <div className="account-settings-section">
                    <p className="cq-heading">Affiliate Protection</p>
                    <div className="field-group">
                        {dispatch(isLoading('updateNetwork')) ? (
                            <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                        ) : (
                            <Switch
                                type="checkbox"
                                className="control-group-toggle"
                                checked={enableAffiliateFraudProtection}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    onChangeAffiliateFraud();
                                }}
                            />
                        )}
                        <div className="field-key line">Enable the Affiliate Protection feature on the account</div>
                    </div>
                </div>
            )}
            <div className="account-settings-section">
                <p className="cq-heading">Blocking POC</p>
                <div className="field-group">
                    {dispatch(isLoading('updateNetwork')) ? (
                        <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                    ) : (
                        <Switch
                            type="checkbox"
                            className="control-group-toggle"
                            checked={networks && !!networks[0]?.isBlockingControlGroup}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                setDialogControlGroupIsOpen(true);
                            }}
                        />
                    )}
                    <div className="field-key line">Blocking control group</div>
                </div>
                {resetPasswordSection()}
                <p className="cq-heading">Account presets</p>
                <div className="field-group">
                    {dispatch(isLoading('updateNetwork')) ? (
                        <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                    ) : (
                        <Switch
                            type="checkbox"
                            className="control-group-toggle"
                            checked={enableFrequencyCapping}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                dispatch(updateNetwork({ id: network.id, isFrequencyCapping: !network.isFrequencyCapping, accountId: account?.id }));
                                !enableFrequencyCapping && dispatch(updateNetworkRule({ networkId: network.id, disableAll: true }));
                            }}
                        />
                    )}
                    <div className="field-key line">Frequency capping</div>
                </div>

                {isPaidMarketingLicenseActivate() && !isDataAndAnalyticsLicenseActivate() && (
                    <>
                        <p className="cq-heading">Google Analytics connector</p>
                        <div className="field-group">
                            {dispatch(isLoading('updateNetwork')) ? (
                                <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                            ) : (
                                <Switch
                                    type="checkbox"
                                    className="control-group-toggle"
                                    checked={enableGAConnector}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        onChangeGAConnector();
                                    }}
                                />
                            )}
                            <div className="field-key line">Show google analytics settings in the account</div>
                        </div>
                    </>
                )}

                {isPixelGuardV2Enabled && (
                    <>
                        <p className="cq-heading">Pixel Guard Version Management</p>
                        <div className="field-group">
                            {dispatch(isLoading('updateNetwork')) ? (
                                <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                            ) : (
                                <Switch
                                    type="checkbox"
                                    className="control-group-toggle"
                                    checked={pxgVersion}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        onChangePxgVersion();
                                    }}
                                />
                            )}
                            <div className="field-key line">Use new version of Pixel Guard</div>
                        </div>
                    </>
                )}
                <>
                    <p className="cq-heading">Marketo - Don’t send integration response</p>
                    <div className="field-group">
                        {dispatch(isLoading('updateNetwork')) || marloading ? (
                            <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                        ) : (
                            <Switch
                                type="checkbox"
                                className="control-group-toggle"
                                checked={isMarketoMonitoring}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    onChangeMarketoToggle();
                                }}
                            />
                        )}
                        <div className="field-key line">The connector will not send verdicts to the CRM even when connector enabled</div>
                    </div>
                </>
                <>
                    <p className="cq-heading">Hubspot - Don’t send integration response</p>
                    <div className="field-group">
                        {dispatch(isLoading('updateNetwork')) || hubloading ? (
                            <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                        ) : (
                            <Switch
                                type="checkbox"
                                className="control-group-toggle"
                                checked={isHubspotMonitoring}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    onChangeHubspotToggle();
                                }}
                            />
                        )}
                        <div className="field-key line">The connector will not send verdicts to the CRM even when connector enabled</div>
                    </div>
                </>

                {enableFrequencyCapping && <FrequencyCapping network={network} />}

                <>
                    <p className="cq-heading">SSO Configuration</p>
                    <div className="field-group">
                        {isEnabledSSOLoading ? (
                            <Progress size={22} style={{ margin: '0px 20px -5px 8px' }} />
                        ) : (
                            <Switch
                                type="checkbox"
                                className="control-group-toggle"
                                checked={!!(newNetworkSettings?.isSSOLoginEnabled)}
                                onChange={(value) => {
                                    onSSOConfiguration(value);
                                }}
                            />
                        )}
                        <div className="field-key line">Show SSO configuration page</div>
                    </div>
                </>
            </div>

            {/* Hide Licenses Features Toggles C4PA-907 */}
            {/* {!account?.isAgency && network && <NetworkFeatures networkFeatures={network?.networkFeatures} onFeatureFlagChange={onFeatureFlagChange} />} */}
        </div>
    );
};

export default SettingsTab;
